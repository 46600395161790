html {
  font-size: 62.5%;

  &.sr .load-hidden {
    visibility: hidden;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: $light-grey;
}
::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}

:target {
  scroll-margin-top: 100px;
}

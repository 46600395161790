#about-2 {
  height: 100%;
  border-top: 0px;

  @include respond(tab-land) {
    height: 100%;
    -webkit-clip-path: none;
    clip-path: none;
  }

  .about-wrapper {
    @include respond(phone) {
      padding-bottom: 5rem;
    }

    &__info {
      display: flex;
      height: 100%;
      justify-content: center;
      flex-direction: column;

      &-text {
        text-align: left;

        @include respond(tab-port) {
          text-align: left;
        }

        &--important {
          background: darken($secondary-color, 1%);
          display: inline-block;
          font-style: italic;
          padding: 0 0.3rem;
          margin: 0.3rem 0;
          line-height: 1.6;

          @include respond(phone) {
            display: inline;
            margin: 0;
            padding: 0;
            background: transparent;
            font-style: normal;
          }
        }
      }
    }
  }
}

.styled-nav-brand {
  // color: ;
  text-align: center;

  font-weight: bold;
}

.styled-nav-brand-button:hover {
  color: $light-grey;
}

.navbar-button {
  border: none;
  background-color: transparent;
  color: #FDFDFF;
  font-size: 2rem;
  font-weight: 700;
  padding: 5px 10px;
}

.navbar-button:hover {
  border: 1px solid $light-grey;
  border-radius: 4px;
  background-color: transparent;
}

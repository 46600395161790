// COLORS
$primary-color: #b92b27;
$secondary-color: #1565c0;

$white-color: #fff;

$dark-grey: #333333;
$light-grey: #d2d2d2;

$dark-blue-text: #272341; // For Headings

// FONT SIZE
$default-font-size: 1.6rem;
$big-font-size: 5rem;
$mid-font-size: 2.5rem;

//GRADIENTS
$enable-gradients: true;

@import "./sass/vendors/bootstrap.scss";

@import "./sass/abstracts/mixins";
@import "./sass/abstracts/variables";
@import "./sass/abstracts/helpers";

@import "./sass/base/base";
@import "./sass/base/typography";

@import "./sass/components/buttons";

@import "./sass/layout/footer";
@import "./sass/layout/sections";

@import "./sass/sections/about";
@import "./sass/sections/technology";
@import "./sass/sections/hero";
@import "./sass/sections/projects";

#technology {
  background-image: linear-gradient(
    to right,
    $primary-color 0%,
    $secondary-color 120%
  );

  -webkit-clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(100% 0, 100% 70%, 0 100%, 0 30%);
  padding: 35rem 0 30rem 0;
  margin-top: -10rem;
  margin-bottom: -1px;
  color: $white-color;

  @include respond(tab-land) {
    padding: 10rem 0;
    clip-path: none;
    margin-top: 0;
    -webkit-clip-path: none;
  }

  .technology-wrapper {
    margin-top: 3.2rem;
    padding: 0 2rem;
    backface-visibility: hidden;

    &__text {
      margin-bottom: 2.5rem;
    }

    &__text,
    a {
      font-size: 2.4rem;

      @include respond(phone) {
        font-size: 2rem;
      }
    }
  }
}

.badge-pill {
  font-size: $default-font-size;
  border: 1px solid $white-color;
}

// Margins
.mb-8 {
  margin-bottom: 8rem !important;
}

.mb-12 {
  margin-bottom: 6rem !important;

  @media (min-width: 992px) {
    margin-bottom: 12rem !important;
  }
}

.mt-12 {
  margin-top: 12rem !important;
}

.gap-right {
  @media (min-width: 992px) {
    margin-right: 5rem !important;
  }
}

.gap-left {
  @media (min-width: 992px) {
    margin-left: 5rem !important;
  }
}

.project-tech-flex {
  margin-top: 2rem !important;

  @media (min-width: 992px) {
    margin-top: 7rem !important;
  }
}

.project-tech {
  background-color: #d1d5db;
  color: black;
  font-weight: 500;
}

.nav-background {
  background-image: linear-gradient(to right, #b92b27 0%, #1565c0 120%);
}
